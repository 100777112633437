import Swiper from 'swiper';
import { A11y, Autoplay, FreeMode } from 'swiper/modules';

new Swiper('.swiper-container', {
  modules: [A11y, Autoplay, FreeMode],
  spaceBetween: 120,
  effect: 'slide',
  loop: true,
  freeMode: true,
  slidesPerView: 6.5,
  speed: 7000,
  autoplay: {
    delay: 1,
    pauseOnMouseEnter: false,
    disableOnInteraction: false,
    waitForTransition: true,
    stopOnLastSlide: false,
  },
  allowTouchMove: false,
  simulateTouch: false,
  breakpoints: {
    320: {
      slidesPerView: 1.85,
      spaceBetween: 60,
    },
    768: {
      slidesPerView: 2.75,
      spaceBetween: 80,
    },
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 100,
    },
    1200: {
      slidesPerView: 5.5,
      spaceBetween: 120,
    },
  }
});
